<!-- @author xinyiliang -->
<!-- @email xinyiliang@yangqianguan.com -->
<!-- @date 2019-05-27 10:39:28.877 -->
<!-- @desc generated by yqg-cli@1.0.0 -->
<!-- @desc use MeScroll: https://github.com/mescroll/mescroll -->

<template>
    <div class="yqg-scroll-loading">
        <div
            v-if="!hideTipTop"
            class="refresh-tip"
            :style="{top: refreshTipTop}"
        >
            {{ isRefreshSuccess ? '更新成功' : '请求超时' }}
        </div>

        <div class="scroll-container">
            <div
                ref="mescroll"
                class="mescroll"
                :style="scrollStyle"
            >
                <div id="slotContent">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'YqgScrollLoading',

        props: {
            refreshTipTopInit: {
                type: Number,
                default: 0
            },

            statusBarHeight: {
                type: Number,
                default: 0
            },

            scrollTop: {
                type: String,
                default: '0'
            },

            useDown: { // 是否启用下拉刷新
                type: Boolean,
                default: false
            },

            downAuto: {
                type: Boolean,
                default: false
            },

            useUp: { // 是否启用上拉加载
                type: Boolean,
                default: false
            },

            upAuto: {
                type: Boolean,
                default: true
            },

            hideTipTop: {
                type: Boolean,
                default: false
            }
        },

        data() {
            const vm = this;

            return {
                isRefreshSuccess: true,

                refreshTipTopExtra: -30,

                down: {
                    use: vm.useDown,

                    auto: vm.downAuto,

                    htmlContent: '<p class="downwarp-progress mescroll-rotate"></p><p class="downwarp-tip">下拉更新</p>',

                    inited(mescroll, downwarp) {
                        // 初始化完毕的回调,可缓存dom
                        mescroll.downTipDom = downwarp.querySelector('.downwarp-tip');
                    },

                    inOffset(mescroll) {
                        mescroll.downTipDom.innerHTML = '下拉更新';
                    },

                    outOffset(mescroll) {
                        // 下拉超过指定距离那一刻的回调
                        mescroll.downTipDom.innerHTML = '松手更新';
                    },

                    showLoading(mescroll) {
                        // 触发下拉刷新的回调
                        mescroll.downTipDom.innerHTML = '更新中';
                    },

                    callback(mescroll) {
                        vm.$emit('downCallBack', mescroll);
                    }
                },

                up: {
                    use: vm.useUp,

                    auto: vm.upAuto,

                    isBoth: true,

                    isBounce: false,

                    htmlLoading: '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">加载中</p>',

                    htmlNodata: '<p class="upwarp-nodata">没有更多内容啦</p>', // 无数据的布局

                    empty: {
                        warpId: 'slotContent',
                        tip: '没有更多内容啦'
                    },

                    callback(page, mescroll) {
                        vm.$emit('upCallBack', page, mescroll);
                    }
                }
            };
        },

        computed: {
            scrollStyle() {
                return {
                    top: this.scrollTop
                };
            },

            refreshTipTop() {
                return `${this.statusBarHeight + this.refreshTipTopInit + this.refreshTipTopExtra}px`;
            }
        },

        async mounted() {
            const {default: MeScroll} = await import('mescroll.js');

            this.mescroll = new MeScroll(this.$refs.mescroll, {
                up: this.up,
                down: this.down
            });

            this.mescroll.yqgDownCallBack = this.yqgDownCallBack;
            this.$emit('init', this.mescroll);
        },

        methods: {
            yqgDownCallBack(status) { // status来判断下拉刷新当前所处状态: 1 接口数据获取成功， 0 请求失败
                this.isRefreshSuccess = status === 1;

                if (this.isRefreshSuccess) {
                    this.mescroll.endSuccess();
                    this.mescroll.resetUpScroll(false);
                } else {
                    this.mescroll.endErr();
                }

                setTimeout(() => {
                    this.refreshTipTopExtra = 0;
                }, 300);

                setTimeout(() => {
                    this.refreshTipTopExtra = -30;
                }, 2000);
            }
        }

    };
</script>

<style lang="scss" rel="stylesheet/scss">
    .yqg-scroll-loading {
        .refresh-tip {
            position: fixed;
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 12px;
            background-color: rgba(61, 59, 238, 50%);
            color: #fff;
            transition: top 300ms;
            z-index: 100;
        }

        /* 下拉刷新和上拉加载的滑动区域 */
        .mescroll {
            position: fixed;
            bottom: 0;
            width: 100%;
            height: auto;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch; // 使iOS列表滑动流畅

            &::-webkit-scrollbar {
                display: none;
            }

            /* 启用硬件加速:使动画渲染流畅,解决部分手机闪白屏问题,在下拉刷新和上拉加载触发时启用,结束后移除,避免滥用导致其他兼容性问题 */
            .mescroll-hardware {
                transform: translateZ(0);
                transform-style: preserve-3d;
                backface-visibility: hidden;
                perspective: 1000;
            }

            /* 下拉刷新区域 */
            .mescroll-downwarp {
                position: relative;
                width: 100%;
                height: 0;
                overflow: hidden;
                text-align: center;

                /* 下拉刷新--内容区,定位于区域底部 */
                .downwarp-content {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    min-height: auto;
                    padding: 0;
                    margin: 20px 0;
                    text-align: center;
                    font-size: 11px;
                    line-height: 16px;
                    color: #a5a5ae;
                }
            }

            /* 下拉刷新--高度重置的过渡动画 */
            .mescroll-downwarp-reset {
                overflow: hidden;
                transition: height 300ms;
            }

            /* 上拉加载区域 */
            .mescroll-upwarp {
                min-height: 30px;
                padding: 20px 0;
                text-align: center;
                visibility: hidden; // 代替display: none,列表快速滑动到底部能及时显示上拉加载的区域
                font-size: 11px;
                line-height: 16px;
                color: #a5a5ae;
            }

            /* 下拉刷新,上拉加载--提示文本 */
            .mescroll-downwarp .downwarp-tip,
            .mescroll-upwarp .upwarp-tip,
            .mescroll-upwarp .upwarp-nodata {
                display: inline-block;
                font-size: 11px;
                color: gray;
                vertical-align: middle;
            }

            .mescroll-downwarp .downwarp-tip,
            .mescroll-upwarp .upwarp-tip {
                margin-left: 8px;
            }

            /* 下拉刷新,上拉加载--旋转进度条 */
            .mescroll-downwarp .downwarp-progress,
            .mescroll-upwarp .upwarp-progress {
                display: inline-block;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 1px solid #a5a5ae;
                border-bottom-color: transparent;
                vertical-align: middle;
            }

            /* 无任何数据的空布局 */
            .mescroll-empty {
                padding: 20px 0;
                text-align: center;
                font-size: 11px;
                line-height: 16px;
                color: #a5a5ae;

                .empty-tip {
                    margin-top: 6px;
                    font-size: 11px;
                    line-height: 16px;
                    color: #a5a5ae;
                }
            }

            /* 旋转动画 */
            .mescroll-rotate {
                animation: mescroll-rotate 0.6s linear infinite;
            }

            @keyframes mescroll-rotate {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

</style>
