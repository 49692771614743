var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-scroll-loading" }, [
    !_vm.hideTipTop
      ? _c(
          "div",
          { staticClass: "refresh-tip", style: { top: _vm.refreshTipTop } },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.isRefreshSuccess ? "更新成功" : "请求超时") +
                "\n    "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "scroll-container" }, [
      _c(
        "div",
        { ref: "mescroll", staticClass: "mescroll", style: _vm.scrollStyle },
        [_c("div", { attrs: { id: "slotContent" } }, [_vm._t("default")], 2)]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }